@media screen and (max-height: 525px) {
  .main-landing {
    grid-template-areas:
      '. . . .'
      'nameW navW navW navW'
      '. .  navW navW'
      '. descW descW .'
      '. iconW iconW .'
      '. downW downW . ';
  }

  .nav-container {
    margin: 0 auto;
  }

  .nav-container a {
    justify-self: center;
    float: left;
    margin: 20px;
  }

  .nav-wrapper-a, .nav-wrapper-b, .nav-wrapper-c, .nav-wrapper-d {
    width: 90%;
    padding: 10px;
    border-radius: 50px;
  }
}

@media screen and (max-width: 720px) {
  .skills-list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

@media screen and (max-height: 400px) {
  .down {
    display: none;
  }
}

@media screen and (max-height: 260px) {
  .icon-container {
    align-self: flex-start;
  }

  .code-icon {
    margin: 0;
  }
}

@media screen and (max-width: 1125px) {
  .name-wrapper {
    width: 60%;
  }

  .main-landing {
    grid-template-areas:
      '. . . .'
      'nameW navW navW navW'
      'descW descW descW .'
      'iconW iconW iconW .'
      '. . . navW'
      '. downW downW . ';
  }

  .about-container {
    width: 80%;
  }

  .project-container {
    grid-template-columns: repeat(3, 32%)
  }
}

@media screen and (max-width: 900px) {
  .about-container {
    width: 90%;
  }

  .image-wrapper {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1020px) {

  .main-landing {
    grid-template-areas:
      'nameW nameW nameW nameW'
      'descW descW descW descW'
      'iconW iconW iconW iconW'
      'navW navW navW navW'
      '. downW downW . ';
  }

  .name-wrapper {
    width: 100%;
    margin-top: 0;
    margin: 0 auto;
    padding: 15px 0;
    border-radius: 0;
  }

  .nav-container {
    margin: 0 auto;
  }

  .nav-container a {
    justify-self: center;
    float: left;
    margin: 20px;
  }

  .nav-wrapper-a, .nav-wrapper-b, .nav-wrapper-c, .nav-wrapper-d {
    width: 90%;
    padding: 10px;
    border-radius: 50px;
  }

  .modal-content, .edu-modal-content {
    grid-template-areas:
      'heading heading heading heading'
      '. cert cert .'
      '. link1 link2 .'
      'desc desc desc desc'
      'desc desc desc desc'
      '. close close . ';
    width: 50%;
  }
}

@media screen and (max-width: 520px) {
  .skills-list {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }

  .nav-container a {
    justify-self: center;
    float: left;
    margin: 10px;
  }

  .nav-container {
    display: none;
  }

  .project-container {
    grid-template-columns: repeat(2, 62%)
  }
}

@media screen and (max-width: 620px) {
  .desc-word-1, .desc-word-2, .desc-word-3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 820px) {
  .image-wrapper {
    width: 100%;
  }

  .about-text-wrapper {
    width: 90%;
  }

  .education-container {
    grid-template-columns: repeat(3, 30%);
  }
  .work-experience-container {
    grid-template-columns: repeat(3, 30%);
  }

  .title-heading-wrapper {
    width: 25%;
  }

  .project-container {
    grid-template-columns: repeat(2, 42%)
  }

  .modal-content, .edu-modal-content {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .connect-paragraph {
    padding: 0px 15px;
  }
}

@media screen and (max-width: 625px) {
  .connect-paragraph {
    padding: 0px 15px;
  }

  .education-container {
    display: block;
  }

  .education-content {
    margin: 20px 0;
  }

  .work-experience-container {
    display: block;
  }

  .work-experience-content {
    margin: 20px 0;
  }

  .course-logo {
    width: 20%;
  }

  .project-container {
    grid-template-columns: repeat(1, 52%)
  }
}

@media screen and (max-width: 530px) {
  .project-container {
    grid-template-columns: repeat(1, 62%)
  }

  .title-heading-wrapper {
    width: 35%;
  }

  .modal-content, .edu-modal-content {
    width: 80%
  }
}

@media screen and (max-width: 400px) {
  .desc-word-1, .desc-word-2, .desc-word-3 {
    font-size: 22px;
  }

  .code-icon {
    font-size: 32px;
  }

  .project-container {
    grid-template-columns: repeat(1, 82%)
  }

  .title-heading-wrapper {
    width: 45%;
  }

  .course-logo, .albion-logo {
    width: 30%;
  }
}
