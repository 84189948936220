.skills-list {
  columns: 3;
-webkit-columns: 3;
-moz-columns: 3;
  list-style:none;
}
.skills-list li {
  list-style:none;
}
.skill {
  width:70%;
  margin: 0 auto;
  padding: 50px 0 50px 0;
  border-top: 1px solid #c3c0c7;
  text-align: left;

}

.skills {
  font-family: "Dosis", sans-serif;
  letter-spacing: 3px;
  font-size: 24px;
}

.skills-paragraph {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}
