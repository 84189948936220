nav {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #0c192c;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-top: 0;
  z-index: 10;
}

.topbar {
  visibility: visibile;
  opacity: 1;
  transition: ease 0.6s;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.logo,
.sm-nav-list,
.nav-list {
  font-family: "Dosis", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 33.33333%;
}

.logo,
.sm-nav-item,
.nav-item {
  list-style: none;
}

.sm-nav-item {
  font-size: 26px;
  padding: 0px 10px;
}

.sm-nav-item a {
  color: white;
  transition: ease 0.4s;
}

.sm-nav-item a:hover {
  color: #EBEAEC;
}
