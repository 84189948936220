.contact {
  margin: 0;
  padding: 50px 0 50px 0;
  border-top: 1px solid #c3c0c7;
  text-align: center;
}

.connect {
  font-family: "Dosis", sans-serif;
  letter-spacing: 3px;
  font-size: 24px;
}

.connect-paragraph {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

.contact-button {
  font-weight: bold;
  cursor: pointer;
  margin-top:10px;
  text-decoration: none;
  border-radius: 5px;
  transition: ease-in-out 0.2s;
  font-size: 14px;
  padding: 20px 50px;
  background-color: white;
  color: black;
  border: 1px solid black;
  font-family: 'PT Sans Narrow', sans-serif;
  letter-spacing: 3px;
}

.contact-button:hover {
  color: white;
  background-color: #453761;
}
