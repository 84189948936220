/* ANIMATIONS */
@keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

/* MAIN VARIABLES */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

ul {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  text-align: center;
  color: white;
  font-family: 'PT Sans Narrow', sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 42px;
  animation: fadeIn 4s;
}

h2 {
  text-align: center;
}

h3 {
  text-align: center;
}

h6,
h5 {
  margin: 0;
  color: white;
  text-align: center;
}

footer {
  background-color: #373045;
  padding: 20px 0;
  text-transform: uppercase;
  color: #373045;
  font-family: 'PT Sans Narrow', sans-serif;
  letter-spacing: 1.2px;
  font-size: 18px;
}

/* GLOBAL CSS STYLES */
.title-heading-wrapper {
  text-align: center;
  width: 20%;
  left: 0;
  padding: 10px;
  background-color: #415065;
  border-radius: 0 50px 50px 0px;
  color: white;
  text-transform: uppercase;
  font-family: 'PT Sans Narrow', sans-serif;
  letter-spacing: 3px;
}

.modal {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
  left: 0;
}

.modal-content, .edu-modal-content {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  grid-template-areas:
    'heading heading heading heading'
    'cert cert link1 link2'
    'cert cert desc desc'
    'cert cert desc desc'
    '. close close . ';
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  margin: auto;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 5px 8px rgba(0, 0, 0, 0.3);
  width: 50%;
}

.heading-wrapper {
  grid-area: heading;
}

.cert-img-wrapper {
  grid-area: cert;
}

.close-button-wrapper {
  grid-area: close;
}

.course-link-wrapper-1 {
  grid-area: link1;
}

.course-link-wrapper-2 {
  grid-area: link2;
}

.description-wrapper {
  grid-area: desc;
}

.cert-img {
  width: 100%;
  height: auto;
}

button {
  font-weight: bold;
  padding: 10px;
  color: white;
  background-color: #415065;
  cursor: pointer;
  margin: 0 auto;
  text-decoration: none;
  border-radius: 5px;
  transition: ease-in-out 0.2s;
  font-size: 14px;
}

button:hover {
  background-color: #546174;
}

.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}
