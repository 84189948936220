body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #0c192c;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-top: 0;
  z-index: 10;
}

.topbar {
  visibility: visibile;
  opacity: 1;
  transition: ease 0.6s;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.logo,
.sm-nav-list,
.nav-list {
  font-family: "Dosis", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 33.33333%;
}

.logo,
.sm-nav-item,
.nav-item {
  list-style: none;
}

.sm-nav-item {
  font-size: 26px;
  padding: 0px 10px;
}

.sm-nav-item a {
  color: white;
  transition: ease 0.4s;
}

.sm-nav-item a:hover {
  color: #EBEAEC;
}

.about {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 40px 0 40px 0;
}

.about-container {
  margin-top: 30px;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-text-wrapper {
  width: 70%;
  text-align: justify;
  font-family: 'Source Sans Pro', sans-serif;
  color: #373045;
}

.image-wrapper {
  width: 30%;
}

.image-container {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
  border: 5px solid #373045;
  ;
  margin: 0 auto;
}

.profile-photo {
  width: 100%;
}

.core-expertise {
  margin-left:5%
}

.education {
  background-color: #ebeaec;
  margin: 0;
  padding: 50px 0 50px 0;
  border-top: 1px solid #c3c0c7;
}

.education-container {
  display: grid;
  grid-gap: 25px;
  justify-content: center;
  grid-template-columns: repeat(3, 25%);
  padding: 20px;
}

.albion-education-content,
.education-content {
  background-color: white;
  box-shadow: 10px 10px 43px -5px rgba(0, 0, 0, 0.75);
  text-align: center;
  padding: 20px;
  border-radius: 5px;
}

.albion-education-content {
  grid-column: span 3;
}

.albion-logo {
  margin-top: 10px;
  width: 20%;
}

.education-container h3 {
  text-transform: uppercase;
  color: #373045;
  font-family: 'PT Sans Narrow', sans-serif;
  letter-spacing: 1.2px;
  font-size: 20px;
  font-weight: bold;
}

.education-container p {
  color: #373045;
  font-family: 'Source Sans Pro', sans-serif;
}

.course-logo {
  padding-top: 10px;
  width: 50%;
}

.edu-link {
  padding: 12px;
  color: white;
  background-color: #17063A;
  margin: 0 auto;
  text-decoration: none;
  border-radius: 3px;
  transition: ease-in-out 0.2s;
}

.edu-link:visited {
  color: white;
}

.edu-link:hover {
  background-color: #453761;
}

.work-experience {
  background-color: #ebeaec;
  margin: 0;
  padding: 50px 0 50px 0;
  border-top: 1px solid #c3c0c7;
}

.work-experience-container {
  display: grid;
  grid-gap: 25px;
  justify-content: center;
  grid-template-columns: repeat(3, 25%);
  padding: 20px;
}

.work-experience-content {
  background-color: white;
  box-shadow: 10px 10px 43px -5px rgba(0, 0, 0, 0.75);
  text-align: center;
  padding: 20px;
  border-radius: 5px;
}


.work-experience-container h3 {
  text-transform: uppercase;
  color: #373045;
  font-family: 'PT Sans Narrow', sans-serif;
  letter-spacing: 1.2px;
  font-size: 20px;
  font-weight: bold;
}

.work-experience-container p {
  color: #373045;
  font-family: 'Source Sans Pro', sans-serif;
}

.course-logo {
  padding-top: 10px;
  width: 50%;
}

.edu-link {
  padding: 12px;
  color: white;
  background-color: #17063A;
  margin: 0 auto;
  text-decoration: none;
  border-radius: 3px;
  transition: ease-in-out 0.2s;
}

.edu-link:visited {
  color: white;
}

.edu-link:hover {
  background-color: #453761;
}

@-webkit-keyframes pjtEnter {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes pjtEnter {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes pjtLeave {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1
  }

  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes pjtLeave {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1
  }

  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0;
  }
}

.projects {
  background-color: #ebeaec;
  border-top: 1px solid #c3c0c7;
  padding: 50px 0 50px 0;
}

.project-container {
  display: grid;
  grid-gap: 25px;
  justify-content: center;
  grid-template-columns: repeat(4, 22%);
  padding: 20px;
}

.project-content {
  background-color: white;
  box-shadow: 10px 10px 43px -5px rgba(0, 0, 0, 0.75);
  text-align: center;
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
}

.project-content:hover .hidden-overlay {
  -webkit-animation: pjtEnter 0.8s forwards;
          animation: pjtEnter 0.8s forwards;
}

.project-content .hidden-overlay {
  background-color: rgba(255, 255, 255, 0.97);
  padding: 10px;
  height: 100%;
  -webkit-animation: pjtLeave 0.8s forwards;
          animation: pjtLeave 0.8s forwards;
}

.lang-icon {
  text-align: center;
  font-size: 24px;
  margin: 2px;
  display: inline-block;
}

.project-date {
  margin: 0;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 14px;
}

.linkButton {
  background-color: #453761;
  color: white;
  padding: 10px;
  border-radius: 50px;
  margin: 3px 4px 2px 0;
  transition: ease-in-out 0.2s;
}

.linkButton:hover {
  background-color: #6a5e80;
}

.project-bio {
  margin-top: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  text-align: justify;
  line-height: 14px;
}

.project-name {
  color: 373045;
  font-family: 'PT Sans Narrow', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  margin: 0px;
}

.skills-list {
  columns: 3;
-webkit-columns: 3;
-moz-columns: 3;
  list-style:none;
}
.skills-list li {
  list-style:none;
}
.skill {
  width:70%;
  margin: 0 auto;
  padding: 50px 0 50px 0;
  border-top: 1px solid #c3c0c7;
  text-align: left;

}

.skills {
  font-family: "Dosis", sans-serif;
  letter-spacing: 3px;
  font-size: 24px;
}

.skills-paragraph {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

.contact {
  margin: 0;
  padding: 50px 0 50px 0;
  border-top: 1px solid #c3c0c7;
  text-align: center;
}

.connect {
  font-family: "Dosis", sans-serif;
  letter-spacing: 3px;
  font-size: 24px;
}

.connect-paragraph {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

.contact-button {
  font-weight: bold;
  cursor: pointer;
  margin-top:10px;
  text-decoration: none;
  border-radius: 5px;
  transition: ease-in-out 0.2s;
  font-size: 14px;
  padding: 20px 50px;
  background-color: white;
  color: black;
  border: 1px solid black;
  font-family: 'PT Sans Narrow', sans-serif;
  letter-spacing: 3px;
}

.contact-button:hover {
  color: white;
  background-color: #453761;
}

@-webkit-keyframes comeInOpp {
  0% {
    -webkit-transform: translate(500px);
            transform: translate(500px);
  }

  100% {
    -webkit-transform: translate(0px);
            transform: translate(0px);
  }
}

@keyframes comeInOpp {
  0% {
    -webkit-transform: translate(500px);
            transform: translate(500px);
  }

  100% {
    -webkit-transform: translate(0px);
            transform: translate(0px);
  }
}

@-webkit-keyframes comeIn {
  0% {
    -webkit-transform: translate(-500px);
            transform: translate(-500px);
  }

  100% {
    -webkit-transform: translate(0px);
            transform: translate(0px);
  }
}

@keyframes comeIn {
  0% {
    -webkit-transform: translate(-500px);
            transform: translate(-500px);
  }

  100% {
    -webkit-transform: translate(0px);
            transform: translate(0px);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  60% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  70% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  80% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  90% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  60% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  70% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  80% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  90% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.icon-container {
  text-align: center;
  -webkit-animation: 6.5s ease 0s normal forwards 1 fadein;
          animation: 6.5s ease 0s normal forwards 1 fadein;
}

.name-wrapper {
  background-color: #415065;
  border-radius: 0px 50px 50px 0px;
  width: 100%;
  padding: 15px;
  align-self: center;
  -webkit-animation: comeIn 3s forwards;
          animation: comeIn 3s forwards;
}

.nav-container a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
}

.nav-container a:visited {
  color: white;
  text-decoration: none;
}

.nav-wrapper-a, .nav-wrapper-b, .nav-wrapper-c, .nav-wrapper-d {
  background-color: #415065;
  border-radius: 50px 0px 0px 50px;
  padding: 20px 30px;
  margin: 10px 0px;
  color: white;
  font-family: 'PT Sans Narrow', sans-serif;
  transition: ease-in-out 0.2s;
  width: 40%;
  float: right;
}

.nav-wrapper-a:hover, .nav-wrapper-b:hover, .nav-wrapper-c:hover, .nav-wrapper-d:hover {
  background-color: #7e7a87;
}

.nav-wrapper-a {
  -webkit-animation: comeInOpp 1.8s forwards;
          animation: comeInOpp 1.8s forwards;
}

.nav-wrapper-b {
  -webkit-animation: comeInOpp 1.6s forwards;
          animation: comeInOpp 1.6s forwards;
}

.nav-wrapper-c {
  -webkit-animation: comeInOpp 1.4s forwards;
          animation: comeInOpp 1.4s forwards;
}

.nav-wrapper-d {
  -webkit-animation: comeInOpp 1.2s forwards;
          animation: comeInOpp 1.2s forwards;
}

.go-down {
  color: white;
  font-size: 50px;
  text-align: center;
  -webkit-animation: bounce 5s infinite;
          animation: bounce 5s infinite;
  transition: ease 0.5s;
}

.go-down:hover {
  color: #d3d2d4;
}

.heading {
  text-align: center;
  padding: 20%;
  color: white;
}

.main-landing {
  display: grid;
  width: 100%;
  height: 50vh;
  padding-top: 5%;
  padding-bottom: 5%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'nameW . . navW'
    '. descW descW navW'
    '. iconW iconW navW'
    '. downW downW navW'
    '. . . .'
    '. . . .'
    '. . . .'
    '. . . .';
    background-color: #0c192c
;
}

.name-wrapper {
  grid-area: nameW
}

.icon-container {
  grid-area: iconW;
  justify-self: center;
}

.desc-word-wrapper {
  grid-area: descW;
  justify-self: center;
  align-self: flex-end;
}

.down {
  grid-area: downW
}

.nav-container {
  grid-area: navW
}

.description-wrapper p {
  font-size: 14px;
  text-align: justify;
}

.code-icon {
  color: white;
  font-size: 40px;
  padding: 0;
  padding-right: 10px;
  float: left;
}

.desc-word-1, .desc-word-2, .desc-word-3 {
  color: white;
  font-size: 32px;
  font-family: 'Fjalla One', sans-serif;
  -webkit-animation: fadeIn 0.7s;
          animation: fadeIn 0.7s;
  text-align: left;
  float: left;
  margin: 10px;
}

/* ANIMATIONS */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

/* MAIN VARIABLES */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

ul {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  text-align: center;
  color: white;
  font-family: 'PT Sans Narrow', sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 42px;
  -webkit-animation: fadeIn 4s;
          animation: fadeIn 4s;
}

h2 {
  text-align: center;
}

h3 {
  text-align: center;
}

h6,
h5 {
  margin: 0;
  color: white;
  text-align: center;
}

footer {
  background-color: #373045;
  padding: 20px 0;
  text-transform: uppercase;
  color: #373045;
  font-family: 'PT Sans Narrow', sans-serif;
  letter-spacing: 1.2px;
  font-size: 18px;
}

/* GLOBAL CSS STYLES */
.title-heading-wrapper {
  text-align: center;
  width: 20%;
  left: 0;
  padding: 10px;
  background-color: #415065;
  border-radius: 0 50px 50px 0px;
  color: white;
  text-transform: uppercase;
  font-family: 'PT Sans Narrow', sans-serif;
  letter-spacing: 3px;
}

.modal {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
  left: 0;
}

.modal-content, .edu-modal-content {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  grid-template-areas:
    'heading heading heading heading'
    'cert cert link1 link2'
    'cert cert desc desc'
    'cert cert desc desc'
    '. close close . ';
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  margin: auto;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 5px 8px rgba(0, 0, 0, 0.3);
  width: 50%;
}

.heading-wrapper {
  grid-area: heading;
}

.cert-img-wrapper {
  grid-area: cert;
}

.close-button-wrapper {
  grid-area: close;
}

.course-link-wrapper-1 {
  grid-area: link1;
}

.course-link-wrapper-2 {
  grid-area: link2;
}

.description-wrapper {
  grid-area: desc;
}

.cert-img {
  width: 100%;
  height: auto;
}

button {
  font-weight: bold;
  padding: 10px;
  color: white;
  background-color: #415065;
  cursor: pointer;
  margin: 0 auto;
  text-decoration: none;
  border-radius: 5px;
  transition: ease-in-out 0.2s;
  font-size: 14px;
}

button:hover {
  background-color: #546174;
}

.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

@media screen and (max-height: 525px) {
  .main-landing {
    grid-template-areas:
      '. . . .'
      'nameW navW navW navW'
      '. .  navW navW'
      '. descW descW .'
      '. iconW iconW .'
      '. downW downW . ';
  }

  .nav-container {
    margin: 0 auto;
  }

  .nav-container a {
    justify-self: center;
    float: left;
    margin: 20px;
  }

  .nav-wrapper-a, .nav-wrapper-b, .nav-wrapper-c, .nav-wrapper-d {
    width: 90%;
    padding: 10px;
    border-radius: 50px;
  }
}

@media screen and (max-width: 720px) {
  .skills-list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

@media screen and (max-height: 400px) {
  .down {
    display: none;
  }
}

@media screen and (max-height: 260px) {
  .icon-container {
    align-self: flex-start;
  }

  .code-icon {
    margin: 0;
  }
}

@media screen and (max-width: 1125px) {
  .name-wrapper {
    width: 60%;
  }

  .main-landing {
    grid-template-areas:
      '. . . .'
      'nameW navW navW navW'
      'descW descW descW .'
      'iconW iconW iconW .'
      '. . . navW'
      '. downW downW . ';
  }

  .about-container {
    width: 80%;
  }

  .project-container {
    grid-template-columns: repeat(3, 32%)
  }
}

@media screen and (max-width: 900px) {
  .about-container {
    width: 90%;
  }

  .image-wrapper {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1020px) {

  .main-landing {
    grid-template-areas:
      'nameW nameW nameW nameW'
      'descW descW descW descW'
      'iconW iconW iconW iconW'
      'navW navW navW navW'
      '. downW downW . ';
  }

  .name-wrapper {
    width: 100%;
    margin-top: 0;
    margin: 0 auto;
    padding: 15px 0;
    border-radius: 0;
  }

  .nav-container {
    margin: 0 auto;
  }

  .nav-container a {
    justify-self: center;
    float: left;
    margin: 20px;
  }

  .nav-wrapper-a, .nav-wrapper-b, .nav-wrapper-c, .nav-wrapper-d {
    width: 90%;
    padding: 10px;
    border-radius: 50px;
  }

  .modal-content, .edu-modal-content {
    grid-template-areas:
      'heading heading heading heading'
      '. cert cert .'
      '. link1 link2 .'
      'desc desc desc desc'
      'desc desc desc desc'
      '. close close . ';
    width: 50%;
  }
}

@media screen and (max-width: 520px) {
  .skills-list {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }

  .nav-container a {
    justify-self: center;
    float: left;
    margin: 10px;
  }

  .nav-container {
    display: none;
  }

  .project-container {
    grid-template-columns: repeat(2, 62%)
  }
}

@media screen and (max-width: 620px) {
  .desc-word-1, .desc-word-2, .desc-word-3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 820px) {
  .image-wrapper {
    width: 100%;
  }

  .about-text-wrapper {
    width: 90%;
  }

  .education-container {
    grid-template-columns: repeat(3, 30%);
  }
  .work-experience-container {
    grid-template-columns: repeat(3, 30%);
  }

  .title-heading-wrapper {
    width: 25%;
  }

  .project-container {
    grid-template-columns: repeat(2, 42%)
  }

  .modal-content, .edu-modal-content {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .connect-paragraph {
    padding: 0px 15px;
  }
}

@media screen and (max-width: 625px) {
  .connect-paragraph {
    padding: 0px 15px;
  }

  .education-container {
    display: block;
  }

  .education-content {
    margin: 20px 0;
  }

  .work-experience-container {
    display: block;
  }

  .work-experience-content {
    margin: 20px 0;
  }

  .course-logo {
    width: 20%;
  }

  .project-container {
    grid-template-columns: repeat(1, 52%)
  }
}

@media screen and (max-width: 530px) {
  .project-container {
    grid-template-columns: repeat(1, 62%)
  }

  .title-heading-wrapper {
    width: 35%;
  }

  .modal-content, .edu-modal-content {
    width: 80%
  }
}

@media screen and (max-width: 400px) {
  .desc-word-1, .desc-word-2, .desc-word-3 {
    font-size: 22px;
  }

  .code-icon {
    font-size: 32px;
  }

  .project-container {
    grid-template-columns: repeat(1, 82%)
  }

  .title-heading-wrapper {
    width: 45%;
  }

  .course-logo, .albion-logo {
    width: 30%;
  }
}

