@keyframes comeInOpp {
  0% {
    transform: translate(500px);
  }

  100% {
    transform: translate(0px);
  }
}

@keyframes comeIn {
  0% {
    transform: translate(-500px);
  }

  100% {
    transform: translate(0px);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  60% {
    transform: translateY(0px);
  }

  70% {
    transform: translateY(-10px);
  }

  80% {
    transform: translateY(0px);
  }

  90% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.icon-container {
  text-align: center;
  animation: 6.5s ease 0s normal forwards 1 fadein;
}

.name-wrapper {
  background-color: #415065;
  border-radius: 0px 50px 50px 0px;
  width: 100%;
  padding: 15px;
  align-self: center;
  animation: comeIn 3s forwards;
}

.nav-container a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
}

.nav-container a:visited {
  color: white;
  text-decoration: none;
}

.nav-wrapper-a, .nav-wrapper-b, .nav-wrapper-c, .nav-wrapper-d {
  background-color: #415065;
  border-radius: 50px 0px 0px 50px;
  padding: 20px 30px;
  margin: 10px 0px;
  color: white;
  font-family: 'PT Sans Narrow', sans-serif;
  transition: ease-in-out 0.2s;
  width: 40%;
  float: right;
}

.nav-wrapper-a:hover, .nav-wrapper-b:hover, .nav-wrapper-c:hover, .nav-wrapper-d:hover {
  background-color: #7e7a87;
}

.nav-wrapper-a {
  animation: comeInOpp 1.8s forwards;
}

.nav-wrapper-b {
  animation: comeInOpp 1.6s forwards;
}

.nav-wrapper-c {
  animation: comeInOpp 1.4s forwards;
}

.nav-wrapper-d {
  animation: comeInOpp 1.2s forwards;
}

.go-down {
  color: white;
  font-size: 50px;
  text-align: center;
  animation: bounce 5s infinite;
  transition: ease 0.5s;
}

.go-down:hover {
  color: #d3d2d4;
}

.heading {
  text-align: center;
  padding: 20%;
  color: white;
}

.main-landing {
  display: grid;
  width: 100%;
  height: 50vh;
  padding-top: 5%;
  padding-bottom: 5%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'nameW . . navW'
    '. descW descW navW'
    '. iconW iconW navW'
    '. downW downW navW'
    '. . . .'
    '. . . .'
    '. . . .'
    '. . . .';
    background-color: #0c192c
;
}

.name-wrapper {
  grid-area: nameW
}

.icon-container {
  grid-area: iconW;
  justify-self: center;
}

.desc-word-wrapper {
  grid-area: descW;
  justify-self: center;
  align-self: flex-end;
}

.down {
  grid-area: downW
}

.nav-container {
  grid-area: navW
}

.description-wrapper p {
  font-size: 14px;
  text-align: justify;
}

.code-icon {
  color: white;
  font-size: 40px;
  padding: 0;
  padding-right: 10px;
  float: left;
}

.desc-word-1, .desc-word-2, .desc-word-3 {
  color: white;
  font-size: 32px;
  font-family: 'Fjalla One', sans-serif;
  animation: fadeIn 0.7s;
  text-align: left;
  float: left;
  margin: 10px;
}
