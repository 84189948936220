@keyframes pjtEnter {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes pjtLeave {
  0% {
    transform: translateY(0%);
    opacity: 1
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.projects {
  background-color: #ebeaec;
  border-top: 1px solid #c3c0c7;
  padding: 50px 0 50px 0;
}

.project-container {
  display: grid;
  grid-gap: 25px;
  justify-content: center;
  grid-template-columns: repeat(4, 22%);
  padding: 20px;
}

.project-content {
  background-color: white;
  box-shadow: 10px 10px 43px -5px rgba(0, 0, 0, 0.75);
  text-align: center;
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
}

.project-content:hover .hidden-overlay {
  animation: pjtEnter 0.8s forwards;
}

.project-content .hidden-overlay {
  background-color: rgba(255, 255, 255, 0.97);
  padding: 10px;
  height: 100%;
  animation: pjtLeave 0.8s forwards;
}

.lang-icon {
  text-align: center;
  font-size: 24px;
  margin: 2px;
  display: inline-block;
}

.project-date {
  margin: 0;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 14px;
}

.linkButton {
  background-color: #453761;
  color: white;
  padding: 10px;
  border-radius: 50px;
  margin: 3px 4px 2px 0;
  transition: ease-in-out 0.2s;
}

.linkButton:hover {
  background-color: #6a5e80;
}

.project-bio {
  margin-top: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  text-align: justify;
  line-height: 14px;
}

.project-name {
  color: 373045;
  font-family: 'PT Sans Narrow', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  margin: 0px;
}
